<template>
  <v-row class="spam">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Spam filter</div>
        <div class="title-input text-right">
          <v-btn @click="newModal = !newModal" elevation="0" class="white--text ml-5" width="184" height="48" color="primary">
            Add filter
          </v-btn>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <v-card flat height="70vh" min-height="260" v-if="!data.length" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">Sorry, but nothing found</div>
        </v-card>
        <div v-else>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="data"
            item-key="id"
            :items-per-page="data.length"
            :height="`calc(100vh - 175px)`"
            class="p-table"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id" height="52">
                  <td>{{ item.value }}</td>
                  <td>
                    <div class="d-flex align-center justify-end">
                      <v-btn class="rounded" text icon tile elevation="0" @click="openEdit(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <span class="divider-vertical"></span>
                      <v-btn class="rounded" color="error" text icon tile elevation="0" @click="openDelete(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <v-dialog v-model="newModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Add spam filter</div>
          <v-textarea
            v-model="newSpam.value"
            label="Spam filter text"
            filled
            outlined
            dense
            required
            color="input"
            rows="3"
            auto-grow
          ></v-textarea>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn :disabled="!newSpam.value" width="184" height="48" color="primary" @click="addSpam">Add</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="newModal = !newModal">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Edit spam filter</div>
          <v-textarea
            v-model="editedItem.value"
            label="Spam filter text"
            filled
            outlined
            dense
            required
            color="input"
            rows="3"
            auto-grow
          ></v-textarea>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn :disabled="!editedItem.value" width="184" height="48" color="primary" @click="updateSpam">Save</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="closeDialog">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Remove the spam filter</div>
          <div class="mt-3 opasity--text">Do you really want to remove the spam filter {{ editedItem.value }}</div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="closeDialog">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="remove">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      newModal: false,
      editModal: false,
      deleteModal: false,
      editedItem: {},
      newSpam: {},
      headers: [
        { text: 'Rules', value: 'value', sortable: false },
        { text: 'Actions', align: 'end', value: 'actions', sortable: false },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'created') {
        this.$notify({ message: 'Spam filter has been created', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'update') {
        this.$notify({ message: 'Spam filter has been upadate', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == '400') {
        this.$notify({ message: 'Error', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openEdit(item) {
      this.editedItem = Object.assign({}, item);
      this.editModal = true;
    },
    openDelete(item) {
      this.editedItem = Object.assign({}, item);
      this.deleteModal = true;
    },
    closeDialog() {
      this.editModal = false;
      this.deleteModal = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    async getData() {
      await this.$store
        .dispatch('getSpam')
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setSpam', {});
      } else if (e.response.status >= 400 && e.response.status < 500) {
        this.notifi('400');
      } else if (e.response.status >= 500) {
        this.notifi('500');
      }
    },
    async addSpam() {
      const data = new Object();
      data.value = this.newSpam.value;
      await this.$store.dispatch('createSpam', data).then(() => {
        this.newModal = false;
        this.notifi('created');
        this.getData();
      });
    },
    async remove() {
      await this.$store.dispatch('deleteSpam', this.editedItem.id).then(() => {
        this.closeDialog();
      });
    },
    async updateSpam() {
      const data = new Object();
      data.value = this.editedItem.value;
      await this.$store
        .dispatch('updateSpam', {
          id: this.editedItem.id,
          data: data,
        })
        .then(() => {
          this.closeDialog();
          this.notifi('update');
          this.getData();
        });
    },
  },
  watch: {
    newModal() {
      this.newSpam = {};
    },
    editModal(val) {
      val || this.closeDialog();
    },
    deleteModal(val) {
      val || this.closeDialog();
    },
  },
  computed: {
    data() {
      return this.$store.getters.spam;
    },
  },
  destroyed() {
    this.$store.dispatch('setSpam', {});
  },
};
</script>
